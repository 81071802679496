import { connector } from '$lib/dataproviders/connector';
export async function LOGIN(username, password, callback, onError) {
	try {
		let url = `/auth/signin`;
		let res = await connector.post(`${url}`, {
			username,
			password
		});
		let data = res;
		let status = res?.data?.status;
		status === 'success' && callback && callback(data);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function PARENT_LOGIN(username, password, callback, onError) {
	// let res = await connector.post(`${`/auth/signin`}`, {
	// 	username,
	// 	password
	// });
	// let test = {
	// 	status: 'success',
	// 	message: "You're successfully logged in",
	// 	data: {
	// 		user: {
	// 			user_id: '16',
	// 			name: '',
	// 			username: 'ifmorah@yahoo.com',
	// 			user_type: 'Parent',
	// 			school_info: {
	// 				id: '0999',
	// 				name: 'COLAB ACADEMY , KADUNA.'
	// 			}
	// 		},
	// 		student: [
	// 			{
	// 				studentid: '09993759',
	// 				name: 'THOMAS YASMIN'
	// 			}
	// 		],
	// 		access: ['result', 'classroom', 'payment', 'cbt'],
	// 		token: res.data.token
	// 	}
	// };
	// callback && callback(test, test.message);
	// onError && onError(null);
	// return;
	try {
		let url = `/auth/parent`;
		let res = await connector.post(`${url}`, {
			username,
			password
		});
		let data = res;
		let status = res.data.status;
		status === 'success' && callback && callback(data);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function REFRESH_USER(callback, onError) {
	try {
		let url = `settings/user`;
		let res = await connector.get(`${url}`);
		let { data } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}
